import React from 'react'

export default function About() {
  return (
    <div tyle={{ background: "rgb(51, 51, 51)" }} className='  w-full'>
<div className='max-w-[1200px] px-4 mx-auto text-black'>
<h2 className='text-center font-bold text-4xl underline my-6'>About Us</h2>
<p className='py-8 text-xl'>
We offer a variety of consulting services at Success Technology training and development, including marketing, strategy, operations, and more. Our team is made up of experts in each of these areas, and we work together to provide our clients with comprehensive solutions that meet their unique needs.

</p>
<p className='py-8 text-xl'>
At Success Technology training and development, our story began with a passion for helping businesses succeed. Our founders saw a need for personalized, results-driven consulting services, and set out to create a company that would meet that need. Today, we continue to uphold that same passion and commitment to excellence
</p>


</div>
    </div>
  )
}
