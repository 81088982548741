import React from 'react'
import { CiFacebook } from "react-icons/ci";


 function Footer() {
  return (
    <div style={{ background: "rgb(51, 51, 51)" }}  className='full text-white  py-10'>
<div className='max-w-[1200px] flex flex-col   justify-center items-center'>
<div className='flex gap-10'>
  <a href="tel:9773710400">
<h2 className=' cursor-pointer'>Mobile Number :-9773710400</h2>

  </a>
<CiFacebook className='text-3xl   mb-10'/>
<a href="mailto:successtechnology82@gmail.com">
<h3 className=' cursor-pointer'>Email Id:-successtechnology82@gmail.com</h3>

</a>
</div>

<p>All Right Reserved. Copyright ©SUCCESS TECHNOLOGY
</p>
</div>
    </div>
  )
}


export default Footer