
import React from 'react'
import Middle from '../component/Middle'
import Banner from '../component/Banner'

export default function Home() {
  return (
    <>
    <Banner/>
    <Middle/>
    </>
  )
}
