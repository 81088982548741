import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaBarsStaggered } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { FaRegArrowAltCircleUp } from "react-icons/fa";




export default function Header() {

  const [toggle, settoggle] = useState(true)


  function changehandle() {
    settoggle(!toggle)
  }
  const menu = [
    {
      name: "Home",
      link: "/"
    }, {
      name: "About US",
      link: "/about"
    }, {
      name: "Contact Us",
      link: "/contact"
    } ,{
      name: "Privacy & policy ",
      link: "/privacy-policy"
    }
    ,{
      name: "Term & Condition ",
      link: "/term-condition"
    }
  ]
  return (
    <div style={{
      background:"rgb(51, 51, 51)"
      }} className='w-full '>
      <div className=' max-w-[1300px] mx-auto py-4 items-center  relative px-4  sm:sticky top-0 text-xl justify-between flex text-[white] '>
      <img className='w-[70px] ms-4' src="image/logo-transparent-svg.svg" alt="" />
        <div className='hidden sm:block'>
          <ul className='flex justify-center items-center  text-lg  gap-16 cursor-pointer'>
            {
              menu.map((d, i) => {
                return <Link key={i} to={d.link}> <li className='hover:text-[#45ce55]  font-bold capitalize' >{d.name}</li></Link>
              })
            }
           
              
          </ul>
        </div>
        {
          toggle == true ?
            <FaBarsStaggered onClick={changehandle} className='sm:hidden cursor-pointer text-2xl block' />
            :
            <ImCross onClick={changehandle} className='sm:hidden cursor-pointer text-2xl block' />

        }
        {/* mobile navbar */}
        <div style={{ zIndex: 1000 }} className={`sm:hidden myshadow font-bold  top-[98px] duration-75  w-[40%] bg-white text-black h-[100vh] absolute block
        ${toggle == false ? 'left-[0%]' : 'left-[-100%]'}
        `}>
          <ul className='flex flex-col text-lg gap-20 pt-10 items-center cursor-pointer'>
            {
              menu.map((d, i) => {
                return <Link key={i} to={d.link}> <li onClick={changehandle} className='hover:text-[#45ce55] hover:font-bold capitalize' >{d.name}</li></Link>
              })
            }
          
          </ul>
        </div>
      </div>
    </div>
  )
}
