import React from 'react'

export default function Banner() {
  return (
  <div className='mb-10'>
    <div className='w-full h-[300px] md:h-[500px]  banner-set'>
    </div>
    <div className='bg-[#a9a9a9] flex flex-col justify-center items-center  gap-8 w-full h-[300px]'>
<h1 className='lg:text-5xl text-3xl	'>Effective Business Advice</h1>
<p>Unlock your company's potential with our consulting services</p>
<a target='_blank' href="https://api.whatsapp.com/send/?phone=9773710400&text=Hello%2C+I+am+interested+in your services">
<button style={{border:"2px solid black"}} className='min-w-[200px] text-lg hover:text-white hover:bg-[black] my-2 p-2 text-[black]'>- Contact US -</button>
</a>

    </div>
    <div  className='bg-[white] flex flex-col justify-center items-center  gap-8 w-full h-[300px]'>
<h1  className='lg:text-3xl text-xl	'>ONLINE APPOINTMENTS</h1>
<p  style={{
background:"rgb(250, 249, 248)"
}} className='min-w-[400px] h-10 text-xl flex justify-center items-center'>
New services are coming soon!
</p>
    </div>
  
  </div>

  )
}
