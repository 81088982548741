import React from 'react'

export default function Candition() {
  return (
    <div>
            <div className='grid px-10 my-10 gap-6 grid-cols-1 md:grid-cols-2'>
        <div>
          <h4 className='mb-10 text-white'>Our policies and term and condition</h4>
          <p className='mycolor  text-lg'>
            <ul>
              <li>   Human Resources and Employment Law - Other downloadable templates of legal documents</li>
              <li>
                Employment Agreement
              </li>
              <li>
                Offer Letter for Employment

              </li>
              <li>
                Remote Work Agreement

              </li>
              <li>
                Internship Agreement

              </li>
              <li>
                Employee Warning Letter

              </li>
              <li>
                Employee Reference Letter

              </li>
              <li>
                Employee Relieving Letter

              </li>
              <li>
                Employee Reimbursement Policy

              </li>
              <li>
                Letter of Termination of Employment

              </li>
              <li>
                Employee Privacy Policy

              </li>
              <li>
                POSH (Prevention of Sexual Harassment) Policy

              </li>
              <li>
                Social Media Policy

              </li>
              <li>
                Employee Handbook

              </li>
              <li>
                Employee Disciplinary Policy
              </li>
              <li>
                Offer Letter for Internship
              </li>
            </ul>
          </p>
        </div>
        <div>

          <img className='w-full md:h-[520px]  h-[300px]' src="image/sidebar3webp.webp" alt="Our Story Image" />

        </div>
      </div>

      <div className="w-full mycolor grid grid-cols-1 leading-[40px] px-10">
        If users abuse your website or mobile app in any way, you can terminate their account. Your "Termination" clause can inform users that their accounts would be terminated if they abuse your service.

        If users can post content on your website or mobile app (create content and share it on your platform), you can remove any content they created if it infringes copyright. Your Terms and Conditions will inform users that they can only create and/or share content they own rights to. Similarly, if users can register for an account and choose a username, you can inform users that they are not allowed to choose usernames that may infringe trademarks, i.e. usernames like Google, Facebook, and so on.
      </div>
    </div>
  )
}
