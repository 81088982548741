import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './pages/Main';
import Home from './pages/Home';
import About from './component/About'
import Banner from './component/Banner';
import Contact from './pages/Contact';
import Candition from './component/Candition';
import Privacy from './component/Privacy';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: '/',
        element: <Home/>,
      },{
        path: '/about',
        element: <About/>,
      },{
        path: '/contact',
        element: <Contact/>,
      },{
        path: '/Privacy-policy',
        element: <Privacy/>,
      } ,,{
        path: '/term-condition',
        element: <Candition/>,
      } 
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
