import React from 'react'
import Banner from '../component/Banner'

export default function () {
  return (
   <div >
    <Banner/>
     <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3501.87097438802!2d77.27861617550083!3d28.633628875664122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM4JzAxLjEiTiA3N8KwMTYnNTIuMyJF!5e0!3m2!1sen!2sin!4v1717688908313!5m2!1sen!2sin" className='w-full my-10 md:h-[500px] h-[300px]'></iframe>
   </div>
  )
}
