import React from 'react'

export default function Middle() {
  return (
    <div style={{ background: "rgb(51, 51, 51)" }} className='text-white  w-full'>
      <div className='text-center text-2xl py-2'>ABOUT Success Technology Training Development</div>
      <div className='max-w-[1200px] mx-auto'>
      <div className='grid px-10 my-10 gap-6 grid-cols-1 md:grid-cols-2'>
        <div>
          <img className='w-full h-[320px] object-cover' src="image/sideimg.jpg" alt="Our Story Image" />
        </div>
        <div>
          <h4 className='mb-10 text-white'>Our Story</h4>
          <p className='mycolor my-20 text-lg'>
            At Success Technology Training Development, our story began with a passion for helping businesses succeed. Our founders saw a need for personalized, results-driven consulting services, and set out to create a company that would meet that need. Today, we continue to uphold that same passion and commitment to excellence.
          </p>
        </div>
      </div>


      <div className='grid px-10 my-10 gap-6 grid-cols-1 md:grid-cols-2'>
        <div >
          <h4 className='mb-10 text-white'>Our Services</h4>
          <p className='mycolor my-20 text-lg'>
            We offer a variety of consulting services at Success Technology Training Development, including marketing, strategy, operations, and more. Our team is made up of experts in each of these areas, and we work together to provide our clients with comprehensive solutions that meet their unique needs.
          </p>
        </div>
        <div>
          <img className='w-full h-[320px] object-cover' src="image/sideimg2.webp" alt="Our Story Image" />

        </div>
      </div>

      <div className='grid px-10 my-10 gap-6 grid-cols-1 md:grid-cols-2'>
        <div >
          <img className='w-full h-[320px] object-cover' src="image/imageside3.webp" alt="Our Story Image" />
        </div>
        <div>
          <h4 className='mb-10 text-white'>Our Approach</h4>
          <p className='mycolor my-20 text-lg'>
            At Success Technology Training Development, we believe that collaboration is key to success. We work closely with our clients to understand their challenges and goals, and develop solutions that are tailored to their unique needs. Our approach is transparent, results-driven, and designed to help businesses thrive.
          </p>
        </div>
      </div>

  
      </div>
      <div className='w-full mt-10  h-[80vh] newset'>
<h1 className='text-center text-4xl text-white pt-6'>Discover What Our Clients Love</h1>
      </div>
    </div>

  )
}